import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { Container, Section as SectonOrg } from '../../shared/Blocks';
import Image from '../../shared/Image';
import MainLayout from '../../shared/MainLayout';
import Header from '../../shared/Marketing/Header';
import { BigTitle } from '../../shared/Marketing/Shared';
import MyButton from '../../shared/MyButton';
import MySEO from '../../shared/MySEO';

const bannerImg = {
  handle: '1x8MTI1SI6C2xHMdScis',
  width: 2368,
  height: 2312
};

const Section = styled(SectonOrg)`
  background-color: #fbfbfb;

  @media screen and (min-width: 1024px) {
    min-height: 700px;
    display: flex;
    justify-content: stretch;
  }
`;

const Panel = styled.div`
  height: 100%;

  .panelImage {
    position: relative;
  }
  .panelContent {
    margin-bottom: 2em;
  }
  .bannerWrp {
    max-width: 550px;
    margin-left: auto;
    position: relative;
    z-index: 1;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    align-items: center;

    .panelImage {
      flex: auto;
    }
    .panelContent {
      flex: 520px 0 0;
      margin-bottom: 4em;
    }
    .bannerWrp {
    }
  }
`;

const Main = styled.main`
  .vh100 {
    height: 100vh;
    min-height: auto;
    overflow: hidden;
  }
  .vh100.noFooter {
    height: calc(100vh - 72px);
    overflow: hidden;
  }
  .StartIELTSOnline {
    font-weight: bold;
    font-size: 20px;
    background: #d14242;
    box-shadow: 0px 8px 13px rgba(209, 66, 66, 0.25);
    border-radius: 8px;
    height: 52px;
    display: inline-flex;
    align-items: center;
    min-width: 188px;
    justify-content: center;
  }
`;

class IndexPage extends React.PureComponent {
  render() {
    return (
      <MainLayout
        showFooterRegister={false}
        noMenu
        noHeader
        noFooter
        hideSideCTA
      >
        <MySEO title="Khóa học online, trải nghiệm như offline" />
        <Header noMenu noRegister />
        <Main>
          <Section selfish className="vh100 noFooter">
            <Container>
              <Panel>
                <div className="panelContent">
                  <BigTitle style={{ marginBottom: '36px' }}>
                    Trải nghiệm khóa học <b>IELTS Online</b> của IELTS Đình Lực{' '}
                    <b>qua 5 bước</b>{' '}
                  </BigTitle>
                  <MyButton href="/online/step1" className="StartIELTSOnline">
                    Bắt đầu
                  </MyButton>
                </div>
                <div className="panelImage">
                  <div className="bannerWrp">
                    <Image
                      image={bannerImg}
                      width={550}
                      backgroundColor="transparent"
                    />
                  </div>
                </div>
              </Panel>
            </Container>
          </Section>
        </Main>
      </MainLayout>
    );
  }
}

IndexPage.propTypes = {
  pageContext: PropTypes.shape({
    meta: PropTypes.string.isRequired
  }).isRequired
};

export default IndexPage;
